import styled from 'styled-components/macro'
import { Helmet as Head } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { PageContainer, PageLoading, i18n, ContainerBlock } from '../components/layout'
import Navigation from '../components/navigation'
import Footer from '../components/footer'
import ScrollToTop from '../components/scroll-to-top'
import { pageView, incident } from '../components/clickstream'

import { fetchMedallionPayManifest, selectPolicyData } from './index.slice'

const BodyContainer = styled('div')`
`
const Error = styled('div')`
  text-align: center;
  font-size: 2rem;
  padding: 2rem 0;
  font-family: Gotham;
`
const PolicyBody = styled('div')`
`

const Body = () => {
  const policyData = useSelector(selectPolicyData);
  const router = useLocation();
  const currentPath = router.pathname;
  const { titleTemplate, sections } = policyData;
  const policy = (sections || []).find(({ href }) => href === currentPath);
  const title = policy?.title;
  const policyBody = policy?.body;
  console.log(router);

  useEffect(() => {
    let clickstreamEvent = policy ? (
      pageView({
        page: policy.title,
        source_content_id: policy.id
      })
    ) : (
      incident({ error_title: '404 - Content Not Found', error_desc: currentPath })
    );
    const sendClickstreamEvent = () => {
      clickstreamEvent.send(true);
    };
    window.addEventListener('beforeunload', sendClickstreamEvent);
    return () => {
      sendClickstreamEvent();
      window.removeEventListener('beforeunload', sendClickstreamEvent);
    }
  }, [policy, currentPath]);

  if (!policy) {
    return (
      <BodyContainer>
        <Head>
          <title>{`MedallionPay - Error`}</title>
        </Head>
        <ContainerBlock>
          <Error>404 - Content Not Found</Error>
        </ContainerBlock>
      </BodyContainer>
    );
  }

  return (
    <BodyContainer>
      <Head>
        <title>{i18n(titleTemplate, { title }) || `MedallionPay - Policy`}</title>
      </Head>
      <ContainerBlock>
        <PolicyBody dangerouslySetInnerHTML={{ __html: policyBody }} />
      </ContainerBlock>
    </BodyContainer>
  )
}

const TermsOr404 = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [pathname]);
  useEffect(() => {
    dispatch(fetchMedallionPayManifest());
  }, [dispatch]);
  const policyData = useSelector(selectPolicyData);
  if (!policyData) return <PageLoading />
  return (
    <PageContainer>
      <Navigation />
      <Body />
      <Footer />
      <ScrollToTop />
    </PageContainer>
  )
}

export default TermsOr404;