import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import styled from 'styled-components/macro'
import ReactPlayer from 'react-player'
import MapboxMap from './mapbox-map'
import { getBaseUrl, fetchContent } from '../pages/index.slice'

import { usePageView } from './clickstream'

import ModalCloseImg from '../img/modal-close.svg'
import ModalPhoneImg from '../img/modal-phone.svg'
import ModalLocationImg from '../img/modal-location.svg';
import ModalWebImg from '../img/modal-web.svg';

const Background = styled('div')`
  background-color: rgba(33, 51, 109, 0.76);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  cursor: pointer;
`

const ScrollContainer = styled('div')`
  height: 100%;
  height: 100vh;
  overflow: scroll;
  display: flex;
  padding: 2rem 0;
  box-sizing: border-box;
`

const PopupContainer = styled('div')`
  background: #F0F0F0;
  border-radius: 2.25rem;
  padding: 1rem 2.75rem 2rem 2.75rem;
  width: 72%;
  max-width: 65rem;
  min-width: 10rem;
  margin: auto;
  cursor: auto;
  position: relative;
  box-sizing: border-box;
`
/*
const Header = styled('img')`
  display: block;
  margin: 0 auto 4.25rem auto;
  max-width: 100%;
`

const Map = styled('img')`
  display: block;
  margin: 0 auto 1.875rem auto;
  width: 100%;
`
*/
/*
const Photo = styled('div')`
  display: block;
  margin: 0 auto 1.5rem auto;
  width: 100%;
  padding-bottom: 43%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${({ src }) => src || ''}');
`
*/
const Photo2 = styled('img')`
  display: block;
  margin: 0 auto 1.5rem auto;
  width: 100%;
`

const Description = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.9;
  color: #373F41;
  text-align: left;
  padding: 0;
  margin-bottom: 2.5rem;

  @media (min-width: 576px) {
    padding: 0 2rem;
  }
`

const ModalClose = styled('div')`
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  width: 1.75rem;
  height: 1.75rem;
  background-image: url('${ModalCloseImg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const PhoneAndAddress = styled('div')`
  @media (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    > *:nth-child(3) {
      flex: 1 0 100%;
    }
  }
`
const Phone = styled('a')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  color: #737B7D;
  padding: 0 2rem;
  background-image: url('${ModalPhoneImg}');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5rem;
  margin: 0.5rem 0;
`
const Address = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  color: #737B7D;
  padding: 0 0 0 2rem;
  background-image: url('${ModalLocationImg}');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  line-height: 1.5rem;
  margin: 0.5rem 0;
`

const Name = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 189.5%;
  color: #373F41;
`

const MapContainerOuter = styled('div')`
  width: 100%;
  padding-bottom: 43%;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 1.125rem;
`
const MapContainerInner = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const HeaderBanner = styled('div')`
  height: 13rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const HeaderBannerImg = styled('img')`
  max-width: 100%;
  max-height: 100%;
`

const Location = styled('div')`
  margin-bottom: 2.25rem;
  @media (min-width: 576px) {
    padding: 0 2rem;
  }
`

const Disclaimer = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 189.5%;
  /* or 30px */
  text-align: center;
  color: #000000;
`

const Website = styled('a')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  color: #737B7D;
  padding: 0 0 0 2rem;
  background-image: url('${ModalWebImg}');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 1.5rem;
  line-height: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0;
  flex: 1;
`

const MapLink = styled('a')`
  flex: 1;
`

const PlayerContainer = styled('div')`
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '56.25'}%;
  background-image: url('${({ backgroundImage }) => backgroundImage || ''}');
  background-size: cover;
`

const MerchantCard = ({ id, mapId, cardId, closePopup, mapSection }) => {
  const dispatch = useDispatch();
  const [videoProps, setVideoProps] = useState(null);
  const [paddingBottom, setPaddingBottom] = useState(1080 / 1920);
  const {
    center,
    zoom,
    pitch,
    rotation,
    sections,
  } = (mapSection || {});
  const merchant = (sections || []).find(({ id }) => id === cardId);
  const {
    alt,
    popupHeader,
    popupPhoto,
    description,
    sections: locations,
    disclaimer,
    videoId,
    idref,
  } = merchant;
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  const isApple = ['iPhone', 'iPad', 'iPod', 'MacIntel'].find(p => navigator?.platform?.includes(p));
  const openCard = useCallback(({ id, mapMarker }) => {
    const mapLink = `${isApple ? 'maps://maps.apple.com' : 'https://maps.google.com'}/maps?q=${mapMarker[0]},${mapMarker[1]}&ll=`;
    window.open(mapLink, '_blank', 'noopener noreferrer')
  }, [isApple]);
  useEffect(() => {
    console.log(videoId);
    if (!videoId) return;
    dispatch(fetchContent(videoId)).then(unwrapResult).then((json) => {
      const data = json?.data?.localizations?.en?.source?.data;
      const { media_url, thumbnail, width, height } = (data || {});
      const {/* hls, mpegdash,*/ raw } = (media_url || {});
      const rawUrl = `${getBaseUrl()}${raw}`;
      //const mpegdashUrl = mpegdash.replace('http:', 'https:').replace(':80', '');
      //const hlsUrl = hls.replace('http:', 'https:').replace(':80', '');
      // sometimes the movies get mangled when being converted to mpegDash/hls
      // eg. tequila lighthouse original movie
      // rather than convert every movie each time, lets just use the rawUrl
      const url = rawUrl; // process.env.REACT_APP_ENV === 'qa' ? rawUrl : ReactPlayer.canPlay(mpegdashUrl) ? mpegdashUrl : ReactPlayer.canPlay(hlsUrl) ? hlsUrl : rawUrl;
      const poster = `${getBaseUrl()}${thumbnail}`;
      const aspectRatioPadding = (height / width) * 100;
      const videoProps = {
        url,
        config: {
          file: {
            attributes: {
              poster,
            },
          },
        },
      };
      setVideoProps(videoProps);
      setPaddingBottom(aspectRatioPadding);
    });
  }, [dispatch, videoId]);
  usePageView({ isPageViewStarted: merchant, pageName: alt, sourceContentId: idref });
  if (!merchant) return null;
  return (
    <Background {...{ id, onClick: closePopup }}>
      <ScrollContainer>
        <PopupContainer onClick={stopPropagation}>
          <ModalClose {...{ onClick: closePopup }} />
          {popupHeader && (
            <HeaderBanner src={popupHeader} alt={alt}>
              <HeaderBannerImg src={popupHeader} alt={alt} />
            </HeaderBanner>
          )}
          {disclaimer && (
            <Disclaimer>{disclaimer}</Disclaimer>
          )}
          {mapSection && (
            <MapContainerOuter>
              <MapContainerInner>
                <MapboxMap {...{
                  center: locations?.[0]?.mapMarker || center,
                  zoom,
                  pitch,
                  rotation,
                  merchants: [merchant],
                  openCard,
                  containerStyle: {
                    width: '100%',
                    height: '100%',
                  }
                }} />
              </MapContainerInner>
            </MapContainerOuter>
          )}
          {popupPhoto && <Photo2 src={popupPhoto} alt="Photo" />}
          {videoProps && (
            <PlayerContainer {...{ paddingBottom }}>
              <ReactPlayer
                controls
                width="100%"
                height="100%"
                style={{ position: 'absolute' }}
                {...videoProps}
              />
            </PlayerContainer>
          )}
          {description && (
            <Description>
              {description}
            </Description>
          )}
          {(locations || []).map(({ name, phones, address, mapMarker, website }, i) => {
            const phonesArray = (Array.isArray(phones) ? phones : [phones]).filter(x => x);
            const addressArray = (Array.isArray(address) ? address : [address]).filter(x => x);
            // phone and address are split by newline for easy address formatting
            const phoneElement = phonesArray.length ? <Phone href={`tel:${phonesArray.join('')}`}>{(phonesArray || []).map((phone, j) => <div key={`locationPhone:${i}:${j}`}>{phone}</div>)}</Phone> : null;
            const addressElement = addressArray.length ? <Address>{(addressArray || []).map((addressLine, j) => <div key={`locationAddress:${i}:${j}`}>{addressLine}</div>)}</Address> : null;
            const mapLink = `${isApple ? 'maps://maps.apple.com' : 'https://maps.google.com'}/maps?q=${mapMarker[0]},${mapMarker[1]}&ll=`;
            const linkElement = mapMarker ? (
              <MapLink href={mapLink} target="_blank" rel="noopener noreferrer">
                {addressElement}
              </MapLink>
            ) : addressElement;
            const websiteElement = website ? <Website href={website}>{website}</Website> : null;
            return (
              <Location key={`popupLocation:${id}:${i}`}>
                {name && <Name>{name}</Name>}
                <PhoneAndAddress>
                  {phoneElement}
                  {websiteElement}
                  {linkElement}
                </PhoneAndAddress>
              </Location>
            )
          })}
        </PopupContainer>
      </ScrollContainer>
    </Background>
  )
}

export default MerchantCard