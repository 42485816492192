import { Fragment } from 'react'
import styled, { keyframes } from 'styled-components/macro'

import LoaderImg from '../img/loading.svg'

export const snakeToTitle = (string) => {
  if (!string) return ''
  return `${string}`.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase())
}
export const newlineToBr = (string) => {
  if (!string) return null
  return `${string}`.split(/[\n|\\\n]/).map((item, i) => {
    return <Fragment key={`newlineToBr.${string}.${i}`}>{item}<br /></Fragment>
  })
}
// todo: translate keys import from content localizable meta
export const i18n = (translateKey, templateData, formatOptions) => {
  if (!translateKey) {
    console.log(`error empty translation key`);
    return null;
  }
  const replacedText = Object.entries(templateData || {}).reduce((collector, [key, value]) => {
    return collector.replace(new RegExp(`{{${key}}}`, 'g'), value)
  }, translateKey).replace(/{{\w+}}/g, '')
  const snakeText = formatOptions?.titleCase ? snakeToTitle(replacedText) : replacedText;
  const outputFragments = formatOptions?.newlineToBr ? newlineToBr(snakeText) : snakeText;
  return outputFragments;
}

// copy of bootstrap container for now
export const ContainerBlock = styled('div')`
  width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
export const Container = styled(ContainerBlock)`
  display: flex;
  align-items: center;
`
export const ContainerFlex = styled(Container)`
  flex-direction: column;
  justify-content: center;
`
export const PageContainer = styled('div')`
  background-color: white;
`

const PageLoadingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background-color: #061d49;
`
const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const PageLoadingSpinner = styled('div')`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
  position: relative;
  width: 10rem;
  &::before {
    content: ' ';
    left: 0;
    width: 10rem;
    height: 10rem;
    animation: ${spinner} 2s linear infinite;
    position: absolute;
    background-image: url('${LoaderImg}');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const PageLoadingMessage = styled('h1')`
  text-align: center;
  font-family: 'Gotham SSm', 'Open Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
`
export const PageLoading = () => (
  <PageLoadingContainer>
    <PageLoadingSpinner />
    <PageLoadingMessage>Loading...</PageLoadingMessage>
  </PageLoadingContainer>
)