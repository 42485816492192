import styled from 'styled-components/macro'
import { LinkListRow } from './link-list'

const Background = styled('div')`
  min-height: 53.875rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('${({ image }) => image}');
  padding-top: 19%;
`

const Title = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 5rem;
  line-height: 5.5rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FAFAFA;
  margin-bottom: 12.5rem;
`

const CardsRow = styled('div')`
`

const BannerCards = ({ bannerCardInfo }) => {
  const { id, image, title, links } = (bannerCardInfo || {});
  return (
    <Background {...{ image }}>
      <Title>{title}</Title>
      <CardsRow>
        <LinkListRow {...{
          id,
          title,
          rowWidth: 4,
          links,
          hoverEffect: 'dropShadow',
        }} />
      </CardsRow>
    </Background>
  )
}

export default BannerCards