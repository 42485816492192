import styled from 'styled-components/macro'
import { ContainerBlock, newlineToBr } from './layout'
import { useSectionView } from './clickstream'

export const ImageTextBackground = styled('section')`
  background-color: #FAFAFA;
`
export const ImageTextContainer = styled(ContainerBlock)`
  padding: ${({ isFirstSection }) => isFirstSection ? '2.5rem' : '1.5rem'} 1rem 1.5rem 1rem;

  @media (min-width: 481px) {
    padding: ${({ isFirstSection }) => isFirstSection ? '8.875rem' : '5.125rem'} 1rem 3rem 1rem;
  }
`
export const Row = styled('div')`
  margin: 0 -1rem;

  @media (min-width: 481px) {
    display: flex;
    align-items: center;
  }
`
export const ImageContainer = styled('div')`
  padding: 0 1rem;
  box-sizing: border-box;
  text-align: center;
  margin: 1.618rem auto;

  @media (min-width: 481px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 auto;
  }
`
export const Image = styled('img')`
  border-radius: 0.75rem;
  width: 61.8%;
  @media (min-width: 481px) {
    width: 100%;
  }
`
export const Text = styled('div')`
  padding: 0 1.5rem;
  box-sizing: border-box;

  @media (min-width: 481px) {
    padding: ${({ isFirstSection }) => /*isFirstSection ? '0' : '2.5rem'*/ '0'} 1.75rem 0 2.25rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
`
export const Title = styled('h2')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 2.625rem;
  line-height: ${({ isFirstSection }) => isFirstSection ? '1' : '3.125rem'};
  color: #373F41;
  margin: 0 0 1.5rem 0;
`
export const Description = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 180%;
  color: #373F41;
  padding-right: 0.5rem;
`
export const Bold = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 140%;
  color: #373F41;
  margin-top: 1.25rem;
`
export const QuoteContainer = styled('div')`
  margin-left: auto;
  padding: 1.5rem 3.75rem 0 1rem;

  @media (min-width: 481px) {
    padding: ${({ isFirstSection }) => isFirstSection ? '3.75rem 1rem' : '3rem 3.75rem'} 0 1rem;
  }
`
export const QuoteText = styled('div')`
  font-family: Gotham SSm;
  font-style: italic;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  letter-spacing: 0.01875rem;
  color: #56CCF2;
`
export const QuoteAuthor = styled('div')`
  font-family: Gotham SSm;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: right;
  letter-spacing: 0.01875rem;
  color: #737B7D;
  padding-right: ${({ isFirstSection }) => isFirstSection ? '0.25rem' : '0'};
`
export const Spacer = styled('div')`
  @media (min-width: 481px) {
    height: 2.125rem;
  }
`
export const ImageText = ({
  idref,
  isFirstSection,
  image,
  imageAlt,
  title,
  description,
  bold,
  quoteText,
  quoteAuthor,
}) => {
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <ImageTextBackground ref={ref}>
      <ImageTextContainer {...{ isFirstSection }}>
        <Row>
          <ImageContainer>
            <Image alt={imageAlt} src={image} />
          </ImageContainer>
          <Text {...{ isFirstSection }}>
            <Title {...{ isFirstSection }}>{title}</Title>
            <Description>
              {description}
            </Description>
            <Bold>
              {bold}
            </Bold>
          </Text>
        </Row>
        {(quoteText || quoteAuthor) ? (
          <Row>
            <QuoteContainer {...{ isFirstSection }}>
              <QuoteText>
                {newlineToBr(quoteText)}
              </QuoteText>
              <QuoteAuthor {...{ isFirstSection }}>
                {quoteAuthor}
              </QuoteAuthor>
            </QuoteContainer>
          </Row>
        ) : <Spacer />}
      </ImageTextContainer>
    </ImageTextBackground>
  )
}

export default ImageText