import styled from 'styled-components/macro'
import { useSectionView } from './clickstream'

const HeroContainer = styled('section')`
  height: 35rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('${({ image }) => image}');
  position: relative;
  overflow: visible;
  height: 38.8vw;
  height: 38.75vw;
`
const BottomText = styled('div')`
  position: absolute;
  left: 50%;
  background: #FFFFFF;
  box-sizing: border-box;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #737B7D;
  //text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-style: solid;
  border-color: #56CCF2;

  border-radius: 2rem;
  border-width: 0.125rem;
  width: 14rem;
  height: 2.5rem;
  margin-left: -7rem;
  bottom: -1.25rem;
  font-size: 0.75rem;

  @media (min-width: 481px) {
    letter-spacing: 0.0125rem;
    border-radius: 4.375rem;
    border-width: 0.1875rem;
    width: 40.125rem;
    height: 5rem;
    margin-left: -20.0625rem;
    bottom: -2.5rem;
    font-size: 1.5625rem;
    padding: 0 1rem;
  }

`
const Hero = ({ id, image, imageAlt, description, idref }) => {
  const { ref } = useSectionView({ pageName: description, sourceContentId: idref });
  return (
    <HeroContainer {...{ id, image }} ref={ref}>
      <BottomText>{description}</BottomText>
    </HeroContainer>
  )
}

export default Hero