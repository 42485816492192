import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

// # selectors
export const selectMedallionPayManifest = state => state.index.medallionPayManifest;
export const selectReferences = createSelector(
  [selectMedallionPayManifest],
  (medallionPayManifest) => {
    return medallionPayManifest?.references;
  }
);
export const textToBool = (text) => {
  const trueFalse = {
    true: true,
    false: false,
  };
  const textOrBool = (text in trueFalse) ? trueFalse[text] : text;
  return textOrBool;
};
export const textToFloat = (text) => {
  const trimmedText = `${text}`.trim();
  const isFloat = trimmedText.match(/^-?\d+\.?\d*$/);
  const float = parseFloat(trimmedText);
  if (isFloat) return float;
  return text;
};
export const stringToFloatArray = (text) => {
  return `${text}`.match(',') ? `${text}`.split(',').map(t => textToFloat(t)) : text;
};
export const splitOnNewline = (text) => {
  return `${text}`.match('\n') ? `${text}`.split('\n') : text;
};
const arrayToHash = (array, key) => {
  return (array || []).reduce((collector, obj) => {
    const { name } = obj;
    const value = obj[key];
    if (['body', 'description'].includes(name)) {
      return {
        ...collector,
        [name]: value,
      }
    }
    if (['phones', 'address'].includes(name)) {
      return {
        ...collector,
        [name]: splitOnNewline(value),
      }
    }
    if (['mapMarker', 'center'].includes(name)) {
      return {
        ...collector,
        [name]: stringToFloatArray(value),
      }
    }
    return {
      ...collector,
      [name]: textToFloat(textToBool(value)),
    }
  }, {});
};
export const getBaseUrl = () => `https://xicms-exm-svc.${process.env.REACT_APP_ENV || 'prod'}.ocean.com`;
const parseSection = ({ references, idref }) => {
  const content = references[idref];
  if (!content) return null;
  const { default_language, template, localizations: localizationsByLanguage, name } = content.data;
  const localizations = (localizationsByLanguage[default_language]?.labels || []).reduce((collector, { name, value }) => {
    return [
      ...collector,
      { name, text: value.text },
    ];
  }, []);
  const localizationsHash = arrayToHash(localizations, 'text');
  const attributes = (content.data.attributes || []);
  const attributesHash = arrayToHash(attributes, 'value');
  const images = (content.data.image_assets || []).reduce((collector, { image, name }) => {
    if (!image) return [
      ...collector,
      { id: idref, name, path: null },
    ];
    const imageContent = references[image.idref];
    const { source: { path, data: { thumbnail } }, title, description } = imageContent.data.localizations[default_language];
    return [
      ...collector,
      { id: image.idref, name, path: `${getBaseUrl()}${path}`, title, description, thumbnail: `${getBaseUrl()}${thumbnail}` }
    ];
  }, []);
  const imagesHash = arrayToHash(images, 'path');
  const thumbnail = (images || []).find(({ name: imageName }) => imageName === 'image')?.thumbnail;
  const sections = (content.data?.sections || []).map(({ idref }) => {
    return parseSection({ references, idref });
  }).filter(x => x);
  return {
    id: idref,
    name,
    ...localizationsHash, ...attributesHash, ...imagesHash,
    idref,
    template,
    sections,
    attributes,
    attributesHash,
    localizations,
    localizationsHash,
    images,
    imagesHash,
    thumbnail,
  };
}
export const selectIndexData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Index');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectDestinationsData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Destinations');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectNavigationData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Navigation');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectFooterData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Footer');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectPolicyData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Policy');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectSocialData = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'Social');
    if (!content) return null;
    return parseSection({ references, idref: content.id });
  }
);
export const selectTermsBody = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'TermsCustomer');
    if (!content) return null;
    return parseSection({ references, idref: content.id }).body;
  }
);
export const selectPrivacyBody = createSelector(
  [selectReferences],
  (references) => {
    const content = Object.values(references || {}).find((content) => content.data.template === 'PrivacyCustomer');
    if (!content) return null;
    return parseSection({ references, idref: content.id }).body;
  }
);
export const indexSelectors = {
  selectMedallionPayManifest,
  selectIndexData,
  selectDestinationsData,
  selectNavigationData,
  selectPolicyData,
  selectSocialData,
  selectTermsBody,
  selectPrivacyBody,
};

// # async actions

export const fetchContent = createAsyncThunk('index/fetchContent', async (contentId, { dispatch, getState }) => {
  const contentData = await fetch(`${getBaseUrl()}/v2/public/content/${contentId}`).then(res => res.ok ? res.json() : Promise.reject());
  return contentData;
});

export const fetchMedallionPayManifest = createAsyncThunk('index/fetchMedallionPayManifest', async (options, { dispatch, getState }) => {
  const indexData = selectIndexData(getState());
  if (indexData) return Promise.reject('manifest already fetched');
  console.log('fetching manifest');
  const medallionPayManifest = await fetch(`${getBaseUrl()}/v2/public/content/59D8E7F1367B5FCBECFF51137EF06BE5?date=${Date.now()}`).then(res => res.json());
  return medallionPayManifest;
});


// # slice
export const indexSlice = createSlice({
  name: 'index',
  initialState: {
    medallionPayManifest: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMedallionPayManifest.fulfilled, (state, action) => {
      state.medallionPayManifest = action.payload;
    })
  }
});

// # sync actions
export const {
  setErrorMessage,
} = indexSlice.actions;

export default indexSlice.reducer;
