import { useState, useCallback, useEffect, useRef } from 'react'
import clickstream from 'exm-clickstream'
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'
import styled from 'styled-components'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

const getBaseXOSUrl = () => `https://xiorchestrate-xos-svc.${process.env.REACT_APP_ENV || 'prod'}.ocean.com:8443/xos`;
const getApplicationId = () => `medallionpay.com:${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENV || 'prod'}`;
const getIsClickstreamEnabled = () => !!Cookies.get('device_id');
// this is only really used for debugging, was created to try to figure out why clickstream events weren't going through
/*
const sendClickstreamEvent = function () {
  const clickstreamEvent = this;
  const data = clickstreamEvent.toJSON();
  const body = JSON.stringify(data);
  return fetch(`${getBaseXOSUrl()}/client/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body,
    mode: "no-cors",
  });
};
*/
export const pageView = ({
  page = document.title,
  source_content_id,
  source_content_uri = window.location.href
}) => {
  console.log('start pageView event', page, source_content_id, source_content_uri);
  const clickstreamEvent = clickstream.createEvent('content_usage', {
    page,
    //template: 'react',
    //source: 'web',
    source_content_type: 'section',
    source_content_id,
    //location: 'web',
    session_id: Cookies.get('session_id'),
    //user_id: Cookies.get('ocean_id'),
    application_id: getApplicationId(),
    //platform: 'web,
    device_id: Cookies.get('device_id'),

    source_content_uri,
  });
  //clickstreamEvent.send = sendClickstreamEvent.bind(clickstreamEvent);
  return clickstreamEvent;
};
export const incident = ({
  page = document.title,
  source_content_id,
  error_id,
  error_title,
  error_count,
  error_desc,
}) => {
  console.log('incident', error_title, error_desc);
  const clickstreamEvent = clickstream.createEvent('incident', {
    page,
    //template: 'react',
    //source: 'web',
    source_content_type: 'section',
    source_content_id,
    //location: 'web',
    session_id: Cookies.get('session_id'),
    //user_id: Cookies.get('ocean_id'),
    application_id: getApplicationId(),
    //platform: 'web,
    device_id: Cookies.get('device_id'),

    error_id,
    error_title,
    error_count,
    error_desc,
  });
  //clickstreamEvent.send = sendClickstreamEvent.bind(clickstreamEvent);
  return clickstreamEvent;
};
//window.incident = incident;
const initializeClickstream = () => {
  const deviceId = Cookies.get('device_id') || uuid();
  const sessionId = Cookies.get('session_id') || uuid();
  Cookies.set('device_id', deviceId, { expires: 365 });
  Cookies.set('session_id', sessionId);
  clickstream.initialize({
    reporting_frequency: 5000,
    xos_url: getBaseXOSUrl(),
    application_id: getApplicationId(),
    device_id: deviceId,
    session_id: sessionId,
    platform: 'web',
    retry_strategy: 'exponential', // or 'simple'
  });
  clickstream.reportEvents();
};

const GdprPopupBackground = styled('div')`
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 1rem 3rem;
  width: 25rem;
  font-family: Gotham;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem black;
  z-index: 2;
  a {
    color: revert;
    text-decoration: revert;
  }
`;
const ButtonRow = styled('div')`
  display: flex;
`
const AcceptButton = styled('button')`
  font-weight: bold;
  flex: 1;
  margin-left: 0.5rem;
`
const CloseButton = styled('button')`
`

const onError = (error) => {
  if (!getIsClickstreamEnabled()) return;
  incident({ error_title: error.type, error_desc: error.message });
};
const onUnload = () => {
  if (!getIsClickstreamEnabled()) return;
  clickstream.reportEvents();
};

const GdprPopup = () => {
  const isClickstreamEnabled = getIsClickstreamEnabled();
  const [popupVisible, setPopupVisible] = useState(!isClickstreamEnabled);
  const onClickOk = useCallback(() => {
    setPopupVisible(false);
  }, []);
  const onClickClose = useCallback(() => {
    setPopupVisible(null);
  }, []);
  useEffect(() => {
    window.addEventListener('error', onError);
    window.addEventListener('beforeunload', onUnload);
  }, []);
  useEffect(() => {
    if (popupVisible===false) {
      initializeClickstream();
    }
  }, [popupVisible]);
  if (!popupVisible) return null;
  return (
    <GdprPopupBackground>
      <p>
        This site tracks visits using cookies. By clicking "Accept", you consent to the use of cookies.
        {' '}
        <a href="https://www.ocean.com/press/01073051021020131091196120726161358" target="_blank" rel="noreferrer">Privacy Policy</a>
      </p>
      <ButtonRow>
        <CloseButton onClick={onClickClose}>Close</CloseButton>
        <AcceptButton onClick={onClickOk}>Accept</AcceptButton>
      </ButtonRow>
    </GdprPopupBackground>
  )
}

const Clickstream = () => {
  return <GdprPopup />;
}

export default Clickstream

export const usePageView = ({ isPageViewStarted, pageName, sourceContentId }) => {
  const clickstreamEvent = useRef();
  useEffect(() => {
    if (!isPageViewStarted || clickstreamEvent.current) return;
    clickstreamEvent.current = pageView({
      page: pageName,
      source_content_id: sourceContentId,
    });
    const sendEvent = () => {
      if (!clickstreamEvent.current) return;
      console.log(`sending pageview clickstream event ${pageName} ${sourceContentId}`);
      clickstreamEvent.current?.send();
      clickstreamEvent.current = null;
    };
    window.addEventListener('beforeunload', sendEvent);
    return () => {
      sendEvent();
      window.removeEventListener('beforeunload', sendEvent);
    };
  }, [isPageViewStarted, pageName, sourceContentId]);
  return {};
};

export const useSectionView = ({ pageName, sourceContentId }) => {
  const clickstreamEvent = useRef();
  const sendEvent = useCallback(() => {
    if (!clickstreamEvent.current) return;
    console.log(`sending sectionview clickstream event ${pageName} ${sourceContentId}`);
    clickstreamEvent.current?.send();
    clickstreamEvent.current = null;
  }, [pageName, sourceContentId]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  window.addEventListener('beforeunload', sendEvent);
  useEffect(() => {
    if (!inView && clickstreamEvent.current) {
      sendEvent();
    }
    if (inView && !clickstreamEvent.current) {
      clickstreamEvent.current = pageView({
        page: pageName,
        source_content_id: sourceContentId,
      });
    }
    return () => {
      sendEvent();
      window.removeEventListener('beforeunload', sendEvent);
    };
  }, [inView, pageName, sendEvent, sourceContentId]);
  return { ref };
};