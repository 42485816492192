import styled from 'styled-components/macro'
import { ContainerFlex } from './layout'
import { useSectionView } from './clickstream'

const CTAContainer = styled(ContainerFlex)`
  text-align: center;
  margin-top: 3.625rem;
  padding: 8.5rem 1rem 6.5rem 1rem;
`

const Background = styled('section')`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${({ image }) => image}');
`

const Title = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  text-align: center;
  color: #FAFAFA;
  margin-bottom: 1.625rem;
`

const Description = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 180%;
  text-align: center;
  color: #FFFFFF;
  max-width: 42rem;
`

const CallToAction = ({ id, idref, image, title, description }) => {
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <Background {...{ id, image }} ref={ref}>
      <CTAContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CTAContainer>
    </Background>
  )
}

export default CallToAction