import styled from 'styled-components/macro';
import { Helmet as Head } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { PageContainer, PageLoading } from '../components/layout';
import Navigation from '../components/navigation';
import Hero from '../components/hero';
import ImageText from '../components/image-text';
import TextImage from '../components/text-image';
import CallToAction from '../components/call-to-action';
import LinkList from '../components/link-list';
import MerchantForm from '../components/merchant-form';
import Footer from '../components/footer';
import ScrollToTop from '../components/scroll-to-top';

import { usePageView } from '../components/clickstream';

import { fetchMedallionPayManifest, selectIndexData } from './index.slice';

const BodyContainer = styled('div')`
`;

const Destinations = (props) => {
  const { title, sections, idref } = props;
  const links = (sections[0]?.sections || []).map((obj) => {
    return {
      ...obj,
      image: obj.tile,
    };
  });
  return (
    <LinkList {...{
      id: 'destinations',
      idref,
      hoverEffect: 'dropShadow',
      title: title,
      links: links,
    }} />
  );
};
const Merchants = (props) => {
  const { title, sections, idref } = props;
  return (
    <LinkList {...{
      id: 'merchants',
      idref,
      backgroundColor: '#F3F6FA',
      backgroundSize: 'contain',
      padding: '1rem 2rem',
      aspectRatioPadding: '61.8%',
      justifyContent: 'flex-start',
      title: title,
      links: sections,
    }} />
  );
};

const Body = () => {
  const indexData = useSelector(selectIndexData);
  const { title, sections } = (indexData || {});
  const componentMap = {
    'Hero': Hero,
    'ImageText': ImageText,
    'TextImage': TextImage,
    'CallToAction': CallToAction,
    'DestinationsSection': Destinations,
    'Merchants': Merchants,
    'MerchantForm': MerchantForm,
  };
  return (
    <BodyContainer>
      <Head>
        <title>{title || `MedallionPay by Carnival`}</title>
      </Head>
      {(sections || []).map((props, i) => {
        const { template } = props;
        const Component = componentMap[template];
        return (
          <Component {...{ key: `section:${i}`, ...props }} />
        );
      })}
    </BodyContainer>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMedallionPayManifest());
  }, [dispatch]);
  const indexData = useSelector(selectIndexData);
  usePageView({ isPageViewStarted: indexData, pageName: indexData?.name, sourceContentId: indexData?.id });
  if (!indexData) return <PageLoading />;
  return (
    <PageContainer>
      <Navigation />
      <Body />
      <Footer />
      <ScrollToTop />
    </PageContainer>
  );
};

export default Home;