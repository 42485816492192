import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'

import { ContainerBlock } from './layout'
import { selectNavigationData } from '../pages/index.slice'

import ExpandNavImg from '../img/expand-nav.svg'
import MpayImg from '../img/mpay.svg'
import XImg from '../img/x.svg'
import MenuImg from '../img/menu.svg';

const NavigationBackground = styled('nav')`
`
const NavigationContainer = styled(ContainerBlock)`
  padding: 0 1rem;
  @media (min-width: 481px) {
    display: flex;
  }
`
const TopRow = styled('div')`
  display: flex;
  align-items: center;
  flex: 0;
  height: 5.5rem;
  position: relative;
  justify-content: center;
  @media (min-width: 481px) {
    display: block;
  }
`
const NavLogo = styled('div')`
  background-image: url('${({ image }) => image || MpayImg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 8rem;
  height: 2.25rem; /* or 100%? */
  /*position: absolute;
  left: 0;*/
  flex: 0 0 8rem;
  display: block;
  height: 5.5rem;
`
export const BottomRow = styled('div')`
  display: ${({ isExpanded }) => isExpanded ? 'block' : 'none'};

  @media (min-width: 481px) {
    margin-top: 0;
    display: flex;
    /*margin: 0 8rem;*/
    flex: 1;
    justify-content: center;
    height: 5.5rem;
    align-items: center;
    padding-right: 8rem;
  }
`
const NavRight = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
const DropdownContainer = styled('div')`
  position: absolute;
  width: 14.5rem;
  background-color: #FAFAFA;
  border-radius: 0.5rem;
  padding: 0.5rem 1.125rem;
  z-index: 2;
`
const NavLinkA = styled('div')`
  height: 2.25rem;
  font-family: Gotham SSm;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0125rem;
  flex: none;
  flex-grow: 0;
  padding: 0 0.875rem;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.25rem;
  cursor: pointer;
  color: ${({ isActive }) => isActive ? '#56CCF2' : '#373F41'};
  background-image: ${({ sections }) => sections?.length ? `url('${ExpandNavImg}')` : 'none'};
  transition: color 53ms;
  :hover {
    color: #56CCF2;
  }
`
const NavLinkContainer = styled('div')`
  position: relative;
  ${DropdownContainer} {
    display: none;
  }
  :hover, :focus {
    ${DropdownContainer} {
      display: block;
    }
    ${NavLinkA} {
      background-image: none;
    }
  }
  /*
  :focus-within {
    ${DropdownContainer} {
      display: block;
    }
  }
  */
`
const DropdownLink = styled('div')`
  display: block;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem
  line-height: 1.5;
  letter-spacing: 0.0125rem;
  color: ${({ isActive }) => isActive ? '#56CCF2' : '#373F41'};
  width: 100%;
  padding: 1rem 0;
  border-top: 0.0625rem solid #C4C4C4;
  transition: color 53ms;
  :hover {
    color: #56CCF2;
  }
`
const RegularLink = styled('a')``
export const NavLink = ({ href, sections, title }) => {
  const router = useLocation();
  const currentPath = router.pathname;
  const isActive = {
    //'/#about': currentPath.includes('/#') || currentPath === '/' || !currentPath,
    //'/#destinations': currentPath.includes('/destinations'),
    /*
    '/#register': false,
    '/merchants': currentPath.includes('/merchants'),
    '/destinations': currentPath.includes('/destinations'),
    '/faq': currentPath.includes('/faq'),
    */
  }[href];
  const LinkComponent = href?.includes('#') || href?.startsWith('/') ? HashLink : RegularLink;
  return (
    <NavLinkContainer>
      <LinkComponent {...{ to: href, href }} aria-haspopup={(!!sections?.length)}>
        <NavLinkA {...{ to: href, href, isActive, sections }}>
          {title}
        </NavLinkA>
      </LinkComponent>
      {sections?.length ? (
        <DropdownContainer aria-label="submenu">
          {(sections || []).map(({ href, title }) => {
            const LinkComponent = href?.includes('#') || href?.startsWith('/') ? HashLink : RegularLink;
            return (
              <LinkComponent {...{ to: href, href }} key={`dropdownLink:${href}`}>
                <DropdownLink {...{ href }}>
                  {title}
                </DropdownLink>
              </LinkComponent>
            )
          })}
        </DropdownContainer>
      ) : null}
    </NavLinkContainer>
  )
}
const HamburgerContainer = styled('button')`
  width: 2.5rem;
  height: 2.5rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${({ isExpanded }) => isExpanded ? XImg : MenuImg}');
  border: 0;
  background-color: transparent;
  cursor: pointer;
  @media (min-width: 481px) {
    display: none;
  }
`
const Hamburger = ({ setIsExpanded, isExpanded }) => {
  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [setIsExpanded, isExpanded]);
  return (
    <NavRight>
      <HamburgerContainer {...{ onClick, isExpanded, 'aria-expanded': isExpanded, 'aria-label': 'Hamburger Button' }} />
    </NavRight>
  )
}
const Navigation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { sections, image } = useSelector(selectNavigationData);
  const tabs = (sections || []).map((props, i) => {
    return (
      <NavLink {...props} key={`navlink:${i}`} />
    );
  });
  return (
    <NavigationBackground role="navigation">
      <NavigationContainer>
        <TopRow>
          <Link to="/" aria-label="Home">
            <NavLogo {...{ image }} />
          </Link>
          <Hamburger {...{ isExpanded, setIsExpanded }} />
        </TopRow>
        <BottomRow {...{ isExpanded }}>
          {tabs}
        </BottomRow>
      </NavigationContainer>
    </NavigationBackground>
  )
}

export default Navigation