import styled from 'styled-components/macro';
import { Helmet as Head } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { fetchMedallionPayManifest, selectDestinationsData } from '../index.slice';

import { i18n, PageContainer, PageLoading } from '../../components/layout';

import Navigation from '../../components/navigation';
import Footer from '../../components/footer';

import BannerCards from '../../components/banner-cards';
import MapSection from '../../components/map-section';

import ScrollToTop from '../../components/scroll-to-top';
import { usePageView } from '../../components/clickstream';

const BodyContainer = styled('div')`
`;

const Body = ({ destination }) => {
  const destinationsData = useSelector(selectDestinationsData);
  const { titleTemplate, sections } = destinationsData;
  const section = (sections || []).find(({ id }) => id === destination);
  const mapSections = section?.sections;
  const bannerCardInfo = { ...section, links: mapSections };

  const { title } = (bannerCardInfo || {});

  usePageView({ isPageViewStarted: section, pageName: section?.name, sourceContentId: section?.idref });
  return (
    <BodyContainer>
      <Head>
        <title>{i18n(titleTemplate, { title }) || `MedallionPay - Destinations: ${title}`}</title>
      </Head>
      <BannerCards {...{
        id: destination,
        idref: section?.idref,
        bannerCardInfo,
      }} />
      {(mapSections || []).map((mapSection, i) => (
        <MapSection {...{ mapSection }} key={`mapSection:${destination}:${i}`} />
      ))}
    </BodyContainer>
  );
};
/*
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.style.scrollBehavior = '';
  }, [pathname]);

  return null;
}
*/

const Destination = () => {
  const { destination } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMedallionPayManifest());
  }, [dispatch]);
  const destinationsData = useSelector(selectDestinationsData);
  if (!destinationsData) return <PageLoading />
  return (
    <PageContainer>
      <ScrollToTop />
      <Navigation />
      <Body {...{ destination }} />
      <Footer />
    </PageContainer>
  )
}

export default Destination

/*
export const getStaticProps = async ({ params }) => {
  return {
    props: { ...params },
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [
      //{ params: { destination: 'index' } },
      { params: { destination: 'alaska' } },
      { params: { destination: 'caribbean' } },
      { params: { destination: 'mexican-riviera' } },
    ],
    fallback: false,
  }
}
*/