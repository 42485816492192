import styled from 'styled-components/macro'
import { newlineToBr } from './layout'
import { ImageTextContainer, Row, ImageContainer, Title, Description, QuoteContainer, QuoteText, QuoteAuthor, Spacer, Image } from './image-text'
import { useSectionView } from './clickstream'

const ImageTextBackground = styled('section')`
  background-color: rgba(60, 100, 177, 0.06);
`

const Text = styled('div')`
  padding: 0 1.5rem;
  box-sizing: border-box;

  @media (min-width: 481px) {
    padding: 2rem 2.25rem 0 1rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const Bold = styled('span')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 140%;
  color: #373F41;
  margin-top: 0.25rem;
`

const TextImage = ({
  idref,
  isFirstSection,
  title,
  description,
  bold,
  imageAlt,
  image,
  quoteText,
  quoteAuthor
}) => {
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <ImageTextBackground ref={ref}>
      <ImageTextContainer>
        <Row>
          <Text {...{ isFirstSection }}>
            <Title {...{ isFirstSection }}>{title}</Title>
            <Description>
              {description}
              {' '}
              <Bold>
                {bold}
              </Bold>
            </Description>
          </Text>
          <ImageContainer>
            <Image alt={imageAlt} src={image} />
          </ImageContainer>
        </Row>
        {(quoteText || quoteAuthor) ? (
          <Row>
            <QuoteContainer {...{ isFirstSection }}>
              <QuoteText>
                {newlineToBr(quoteText)}
              </QuoteText>
              <QuoteAuthor {...{ isFirstSection }}>
                {quoteAuthor}
              </QuoteAuthor>
            </QuoteContainer>
          </Row>
        ) : <Spacer />}
      </ImageTextContainer>
    </ImageTextBackground>
  )
}

export default TextImage