import { useState, useCallback } from 'react'
import styled from 'styled-components/macro'

import MarkerImg from '../img/marker.svg';

import ReactMapbox, { Marker, Popup } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// https://stackoverflow.com/a/66140436/2077884
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapFactory = ReactMapbox({
  accessToken: 'pk.eyJ1IjoiZ3hpLWNyZWF0aXZlIiwiYSI6ImNrdXN0djUxNjNtZ3IycG14Z2JwZmx1cTcifQ.o0f7sQb6jeUKWbopEdfnjA',
  minZoom: 12.5,
  logoPosition: 'top-right'
  //antialias: true,
});

const MarkerPopupContainer = styled('div')`
  text-align: center;
  max-width: 16rem;
`
const PopupImage = styled('img')`
  height: 3rem;
  margin-bottom: 0.25rem;
`
const MarkerHover = styled('div')`
  position: relative;
  cursor: pointer;
  &:hover {
    ${MarkerPopupContainer} {
      //display: block;
    }
  }
`
const PopupTitle = styled('div')`
  font-weight: bold;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: #373F41;
  margin-bottom: 0.3125rem;
`
const PopupDescription = styled('div')`
  display: -webkit-box;
  max-width: 16rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Gotham;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  color: #373F41;
`
const MarkerImage = styled('div')`
  width: 2.5625rem;
  height: 3.8125rem;
  background-image: url('${MarkerImg}');
  background-size: contain;
  background-repeat: no-repeat;
`

const MerchantLocationMarker = ({ merchant, location, openCard, setPopupData }) => {
  const { id } = merchant;
  const { mapMarker } = location;
  const openMerchantCard = useCallback(() => {
    if (!openCard) return;
    openCard({ id, mapMarker });
  }, [openCard, id, mapMarker]);
  const showPopup = useCallback(() => {
    setPopupData({
      merchant,
      location,
    });
  }, [
    setPopupData,
    merchant,
    location,
  ]);
  const hidePopup = useCallback(() => setPopupData(null), [setPopupData]);
  if (!mapMarker) return null;
  return (
    <>
      <Marker
        coordinates={[mapMarker[1], mapMarker[0]]}
        anchor="bottom"
        onClick={openMerchantCard}
      >
        <MarkerHover onMouseOver={showPopup} onMouseOut={hidePopup}>
          <MarkerImage />
        </MarkerHover>
      </Marker>
    </>
  );
};

const MarkerPopup = ({ popupData, openCard }) => {
  const { merchant, location } = (popupData || {});
  const { id, alt, image, description } = (merchant || {});
  const { mapMarker, markerName, name } = (location || {});
  const openMerchantCard = useCallback(() => {
    if (!openCard) return;
    openCard({ id, mapMarker });
  }, [openCard, id, mapMarker]);
  if (!popupData) return null;
  return (
    <Popup
      coordinates={[mapMarker[1], mapMarker[0]]}
      anchor="bottom"
      offset={50}
    >
      <MarkerPopupContainer onClick={openMerchantCard}>
        <PopupImage alt={alt} src={image} />
        <PopupTitle>{markerName || alt || name}</PopupTitle>
        <PopupDescription>{description}</PopupDescription>
      </MarkerPopupContainer>
    </Popup>
  );
}

const MapboxInner = ({ merchants, openCard }) => {
  const [popupData, setPopupData] = useState(null);
  return (
    <div>
      {(merchants || []).map((merchant, i) => {
        const { sections: locations } = merchant;
        return (
          <div key={`merchantMarkers:${i}`}>
            {(locations || []).map((location, j) => (
              <MerchantLocationMarker key={`merchantMarker:${i}:${j}`} {...{ merchant, location, openCard, setPopupData }} />
            ))}
          </div>
        );
      })}
      <MarkerPopup {...{ popupData, openCard }} />
    </div>
  )
}

const UnusedOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75));
`

const MapboxMap = ({
  center = [19.677626145548505, -155.08929422184818],
  zoom = 12,
  pitch = 38,
  rotation = 0,
  merchants,
  openCard,
  isUnused,
  containerStyle,
}) => {
  return (
    <MapFactory
      //style="mapbox://styles/mapbox/streets-v9"
      //style="mapbox://styles/nicorga/ckhntcedy16j219mwtdl20s13"
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/gxi-creative/ckustqvsy05ft14p00isnl7g2"
      containerStyle={containerStyle}
      center={[center[1], center[0]]}
      zoom={[zoom]}
      pitch={[pitch]}
      bearing={[rotation]}
    >
      <MapboxInner {...{ merchants, openCard }} />
      {isUnused && <UnusedOverlay />}
    </MapFactory>
  )
};

export default MapboxMap