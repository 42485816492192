import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { ContainerBlock } from './layout'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useSectionView } from './clickstream'

const Background = styled('section')`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
`

const Container = styled(ContainerBlock)`
  padding: 6.75rem 1.5rem;
`

const Title = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 2.625rem;
  line-height: 3.125rem;
  text-align: center;
  color: #373F41;
`

const Row = styled('div')`
  display: block;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  padding: 3rem 0 6.5rem 0;
  width: ${({ rowWidth }) => rowWidth || 'auto'};
  flex-wrap: wrap;

  @media (min-width: 481px) {
    display: flex;
    margin: ${({ rowMargin }) => rowMargin || '0'};
  }
`

const ImageLinkImage = styled('div')`
  padding-top: ${({ aspectRatioPadding }) => aspectRatioPadding || '75%'};
  background: ${({ isUnused, image }) => isUnused ? `linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url('${image}')` : `url('${image}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ backgroundSize }) => backgroundSize || 'cover'};
  width: ${({ imageWidth }) => imageWidth || '100%'};
  margin: 0 auto;
  box-shadow: 0 0.25rem 1.875rem rgba(15, 34, 115, 0);
  transition: box-shadow 214ms, filter 214ms, -webkit-filter 214ms;
  border-radius: 0.5rem;
  ${({ hoverImage }) => hoverImage ? `
  &:hover {
    background-image: url('${hoverImage}');
  }
  ::after {
    content: ' ';
    position: absolute;
    visibility: hidden;
    background-image: url('${hoverImage}');
  }
  ` : ``}
`
// no hoverImage support. no old browser support. isUnused not working (for ImageLinkImg)
const ImgContainer = styled('div')`
  background: ${({ isUnused, image }) => isUnused ? `linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url('${image}')` : `url('${image}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ backgroundSize }) => backgroundSize || 'cover'};
  width: ${({ imageWidth }) => imageWidth || '100%'};
  margin: 0 auto;
  box-shadow: 0 0.25rem 1.875rem rgba(15, 34, 115, 0);
  transition: box-shadow 214ms, filter 214ms, -webkit-filter 214ms;
  border-radius: 0.5rem;
  position: relative;
`
const ImgImg = styled('img')`
  aspect-ratio: 16 / 9;
  object-fit: ${({ backgroundSize }) => backgroundSize || 'cover'};
  width: ${({ imageWidth }) => imageWidth || '100%'};
  margin: 0 auto;
  box-shadow: 0 0.25rem 1.875rem rgba(15, 34, 115, 0);
  transition: box-shadow 214ms, filter 214ms, -webkit-filter 214ms;
  border-radius: 0.5rem;
`

const ImgOverlay = styled('div')`
  aspect-ratio: 16 / 9;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75));
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
`;

const ImageLinkImg = ({ loading = 'lazy', image, backgroundSize, isUnused, ...other }) => (
  <ImgContainer {...{ isUnused, ...other }}>
    <ImgImg {...{ loading, src: image, backgroundSize }} />
    {isUnused ? <ImgOverlay /> : null}
  </ImgContainer>
)

const ImageLinkTitle = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  color: ${({ isUnused }) => isUnused ? '#C4C4C4' : '#373F41'};

  position: absolute;
  bottom: 0;
  left: 50%;
  width: 75%;
  margin-left: -37.5%;

  background: #FFFFFF;
  box-shadow: 0 0.25rem 1.875rem rgba(15, 34, 115, 0.1);
  border-radius: 0.5rem;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  transition: box-shadow 214ms, filter 214ms, -webkit-filter 214ms;
`
const ImageLinkContainer = styled('div')`
  display: block;
  flex: 0 0 ${({ rowWidth = 3 }) => (1 / rowWidth) * 100}%;
  padding: ${({ padding }) => padding || '0 1.5rem'};
  position: relative;
  cursor: ${({ href, linkCallback }) => href || linkCallback ? 'pointer' : 'auto'};

  ${({ hoverEffect }) => {
    if (hoverEffect === 'dropShadow') {
      return `
        &:hover {
          ${ImageLinkImage}, ${ImageLinkTitle} {
            box-shadow: 0.25rem 0.25rem 1.875rem rgba(15, 34, 115, 0.2);
          }
        }
      `;
    }
    if (hoverEffect === 'zoom') {
      return `
        transition: transform 250ms;
        transform-origin: center;
        transform: scale(0.83);
        &:hover {
          transform: scale(1);
        }
      `;
    }
    return `
      transform-origin: center;
      transform: scale(0.83);
    `;
  }}
  /*
  margin: 0 0 4rem 0;
  @media (min-width: 481px) {
    margin: 0;
  }
  */
  padding-bottom: 3rem;
  margin: 0 0 1rem 0;
`
/*
const ImageLinkLink = styled(HashLink)`
  display: block;
  flex: 0 0 ${({ rowWidth = 3 }) => (1 / rowWidth) * 100}%;
  padding: ${({ padding }) => padding || '0 1.5rem'};
  position: relative;
  cursor: ${({ href, linkCallback }) => href || linkCallback ? 'pointer' : 'auto'};

  ${({ hoverEffect }) => {
    if (hoverEffect === 'dropShadow') {
      return `
        &:hover {
          ${ImageLinkImage}, ${ImageLinkTitle} {
            box-shadow: 0.25rem 0.25rem 1.875rem rgba(15, 34, 115, 0.2);
          }
        }
      `;
    }
    if (hoverEffect === 'zoom') {
      return `
        transition: transform 250ms;
        transform-origin: center;
        transform: scale(0.83);
        &:hover {
          transform: scale(1);
        }
      `;
    }
    return `
      transform-origin: center;
      transform: scale(0.83);
    `;
  }}

  margin: 0 0 4rem 0;
  @media (min-width: 481px) {
    margin: 0;
  }
`
*/
const BasicLink = styled('a')``
const ImageLink = ({ link, imageWidth, hoverEffect, backgroundSize, rowWidth, linkCallback, padding, aspectRatioPadding }) => {
  const { id, image, hoverImage, href, title, alt, isUnused, thumbnail } = link;
  const onClick = useCallback((e) => {
    if (linkCallback) return linkCallback({ id });
    if (!href?.includes('#')) document.documentElement.style.scrollBehavior = 'auto';
  }, [linkCallback, id, href]);
  const LinkComponent = href?.includes('#') ? HashLink : href?.startsWith('/') ? Link : BasicLink;
  const imageUrl = (thumbnail && thumbnail.includes('.svg')) ? image : thumbnail; // const imageUrl = image;
  //useSectionView({ pageName: title, sourceContentId: id });
  return (
    <ImageLinkContainer {...{ hoverEffect, rowWidth, linkCallback, padding }}>
      <LinkComponent {...{ to: href, href, onClick, title: alt, padding }}>
        <ImageLinkImg {...{ image: imageUrl, hoverImage, imageWidth, backgroundSize, isUnused, aspectRatioPadding }} />
        {title ? (
          <ImageLinkTitle {...{ isUnused }}>
            {title}
          </ImageLinkTitle>
        ) : null}
      </LinkComponent>
    </ImageLinkContainer>
  )
}

export const LinkListRow = ({ id, idref, title, links, rowMargin, imageWidth, hoverEffect, backgroundSize, rowWidth, linkCallback, padding, aspectRatioPadding, justifyContent }) => {
  return (
    <Row {...{ rowMargin, justifyContent }}>
      {(links || []).map((link, i) => (
        <ImageLink {...{ link, imageWidth, hoverEffect, backgroundSize, rowWidth, linkCallback, padding, aspectRatioPadding }} key={`linkList:${id}:${i}`} />
      ))}
    </Row>
  )
}

const LinkList = ({ id, idref, title, links, backgroundColor, imageWidth, rowMargin, hoverEffect, backgroundSize, rowWidth, padding, aspectRatioPadding, justifyContent }) => {
  //useSectionView({ pageName: title, sourceContentId: id });
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <Background {...{ id, backgroundColor }} ref={ref}>
      <Container>
        <Title>{title}</Title>
        <LinkListRow {...{ id, idref, title, links, rowMargin, imageWidth, hoverEffect, backgroundSize, rowWidth, padding, aspectRatioPadding, justifyContent }} />
      </Container>
    </Background>
  )
}

export default LinkList