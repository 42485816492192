import { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { ContainerFlex } from './layout'
import { useSectionView } from './clickstream'

const Background = styled('section')`
  padding: 6.75rem 1rem;
  @media (min-width: 481px) {
    padding: 13.5rem 1rem 8rem 0;
  }
`
const Title = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 2.625rem;
  line-height: 3.125rem;
  text-align: center;
  color: #373F41;
  margin-bottom: 4.5rem;
`
const FormContainer = styled('form')`
  max-width: 32.75rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`
const FormInput = styled('input')`
  flex: 1;

  padding: 1.375rem 2rem;
  border: 0.0625rem solid #333333;
  box-sizing: border-box;
  border-radius: 0.375rem;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  ::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: rgba(19, 39, 67, 0.5);
  }

  grid-column: 1 / span 2;
`
const FormHalfInput = styled(FormInput)`
  grid-column: span 1;
`
const FormTextarea = styled('textarea')`
  flex: 1;
  height: 12.75rem;

  padding: 1.375rem 2rem;
  border: 0.0625rem solid #333333;
  box-sizing: border-box;
  border-radius: 0.375rem;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  ::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: rgba(19, 39, 67, 0.5);
  }

  grid-column: 1 / span 2;
`
const SubmitButton = styled('a')`
  background: linear-gradient(260.26deg, #00ADE3 24.54%, #55D0BA 87.73%);
  border-radius: 0.375rem;
  margin: 0.125rem auto 0 auto;
  line-height: 3.125rem;
  min-width: 10rem;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  color: #FFFFFF;
  cursor: pointer;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  grid-column: 1 / span 2;
`

const SuccessMessage = styled('div')`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  text-align: center;

  grid-column: 1 / span 2;
`

const MerchantForm = ({ id, idref, title, submitMessage, submitButton, sections, attributesHash }) => {
  const { register, handleSubmit, watch, /* errors */ } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, firstName, lastName, phoneNumber, companyName, merchantLocation, otherNotes] = [watch('email'), watch('firstName'), watch('lastName'), watch('phoneNumber'), watch('companyName'), watch('merchantLocation'), watch('otherNotes')]
  const href = `mailto:onboard@medallionpay.com?subject=MedallionPay Merchant Account Application&body=First Name: ${firstName}\nLast Name: ${lastName}\nPhone Number: ${phoneNumber}\nCompany Name: ${companyName}\nMerchant Location: ${merchantLocation}\nOther Notes: ${otherNotes}\n`.replace(/\n/g, '%0D%0A');
  const openEmail = useCallback(() => {
    window.open(href);
  }, [href])
  const sendEmail = useCallback(() => {
    fetch("https://formsubmit.co/ajax/onboard@medallionpay.com",
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email, firstName, lastName, phoneNumber, companyName, merchantLocation, otherNotes
        })
      })
      .then(response => response.json())
      .then(data => console.log(data))
      .then(() => setFormSubmitted(true))
      .catch(error => console.log(error));
  }, [email, firstName, lastName, phoneNumber, companyName, merchantLocation, otherNotes]);
  const onSubmit = `${typeof window !== "undefined" && window.location.search}`.includes('sendEmail=true') ? sendEmail : openEmail;
  const inputComponents = (sections[0]?.localizations || []).map(({ name, text }) => {
    const InputComponent = {
      FormInput,
      FormHalfInput,
      FormTextarea
    }[sections[0]?.attributesHash?.[name]] || FormInput;
    return (
      <InputComponent key={name} placeholder={text} {...register(name)} />
    );
  });
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <Background {...{ id }} ref={ref}>
      <ContainerFlex>
        <Title>{title || `Apply for a Merchant Account Today`}</Title>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {inputComponents}
          <SubmitButton type="submit" onClick={onSubmit}>{submitButton || `Apply Now`}</SubmitButton>
          {formSubmitted ? <SuccessMessage>{submitMessage || `Your message has been sent!`}</SuccessMessage> : null}
        </FormContainer>
      </ContainerFlex>
    </Background>
  )
}

export default MerchantForm