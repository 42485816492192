import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const onEnter = () => {
  // https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
  window.scrollTo(0, 0);
  document.documentElement.style.scrollBehavior = '';
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
  // https://stackoverflow.com/a/44549737/2077884
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    onEnter();
  }, [pathname]);

  return null;
};

export default ScrollToTop;