import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ContainerBlock } from './layout'
import { NavLink } from './navigation'
import { selectFooterData, selectPolicyData, selectSocialData } from '../pages/index.slice'

import FooterLogoImg from '../img/footer-logo.svg'

const FooterContainer = styled('footer')`
  background-color: #F2F2F2;
`
const LogoRow = styled('div')`
  padding: 2.25rem 0 1rem 0;
`
const NavRow = styled('div')`
  display: flex;
  justify-content: center;
`
const Logo = styled('div')`
  display: block;
  margin: 0 auto;
  height: 2.5rem;
  width: 11.25rem;
  padding: 1rem 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${({ image }) => image || FooterLogoImg}');
  box-sizing: content-box;
`
const Hr = styled('div')`
  border: 0.0625rem solid #56CCF2;
  opacity: 0.2;
  margin: 1.25rem 0;
`
const BottomRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem 1.5rem 1rem;
`
const Copyright = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #373F41;
`
const SocialLinks = styled('div')`
  display: flex;
  margin-left: auto;
`
const SocialLink = styled('div')`
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${({ image }) => image}');
  margin: 0 0 0 1rem;
`
const TermsAndConditions = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #373F41;
  margin: 0 0 0 1rem;
  text-decoration: underline;
`
const Footer = () => {
  const { image, sections: footerSections } = useSelector(selectFooterData);
  const { copyright, sections: policySections } = useSelector(selectPolicyData);
  const { sections: socialSections } = useSelector(selectSocialData);
  const tabs = (footerSections || []).map(({ sections, ...otherProps }, i) => {
    return (
      <NavLink {...otherProps} key={`footerLink:${i}`} />
    );
  });
  const policies = (policySections || []).map(({ href, title }, i) => {
    return (
      <Link to={href} key={`policyLink:${i}`}>
        <TermsAndConditions>{title}</TermsAndConditions>
      </Link>
    )
  });
  const social = (socialSections || []).map(({ href, title, image }, i) => {
    return (
      <a href={href} aria-label={title} key={`socialLink:${i}`}>
        <SocialLink image={image} />
      </a>
    )
  });
  return (
    <FooterContainer>
      <ContainerBlock>
        <LogoRow>
          <Link to="/" aria-label="Home">
            <Logo {...{ image }} />
          </Link>
        </LogoRow>
        <NavRow>
          {tabs}
        </NavRow>
        <Hr />
        <BottomRow>
          <Copyright>{copyright || `© 2020 O•C•E•A•N®. All rights reserved`}</Copyright>
          {policies}
          <SocialLinks>
            {social}
          </SocialLinks>
        </BottomRow>
      </ContainerBlock>
    </FooterContainer>
  )
}

export default Footer