import { useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import 'mapbox-gl/dist/mapbox-gl.css';

import { ContainerBlock } from './layout'
import { LinkListRow } from './link-list'
import MerchantCard from './merchant-popup'
import MapboxMap from './mapbox-map'

import { useSectionView } from './clickstream';

const Background = styled('section')`
  margin-bottom: 7rem;
`
/*
const Map = styled('div')`
  min-height: 25rem;
  height: 28vh;
  background: ${({ isUnused, image }) => isUnused ? `linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url('${image}')` : `url('${image}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
*/
const Title = styled('div')`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 3.75rem;
  text-align: center;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  background: ${({ isUnused }) => isUnused ? `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #56A5D5` : `#56A5D5`};
`

const Merchants = styled(ContainerBlock)`
`

const MapSection = ({ mapSection }) => {
  const { id, idref, title, /* image, */ isUnused, center, zoom, pitch, rotation, sections } = mapSection
  const [cardId, setCardId] = useState(null)
  const merchants = sections;
  const openCard = useCallback(({ id }) => {
    console.log('openCard', id);
    setCardId(id)
    document.body.style.overflow = 'hidden'
  }, [setCardId])
  const closePopup = useCallback(() => {
    setCardId(null)
    document.body.style.overflow = ''
  }, [setCardId]);
  const { ref } = useSectionView({ pageName: title, sourceContentId: idref });
  return (
    <Background {...{ id }} ref={ref}>
      {/*<Map {...{ image, isUnused }} />*/}
      <MapboxMap {...{
        center,
        zoom,
        pitch,
        rotation,
        merchants,
        openCard,
        isUnused,
        containerStyle: {
          height: '28vh',
          width: '100vw',
          minHeight: '25rem',
        },
      }} />
      <Title {...{ isUnused }}>{title}</Title>
      <Merchants>
        <LinkListRow {...{
          id: `map-merchants-${id}`,
          title,
          rowMargin: '0 6rem',
          backgroundSize: 'contain',
          hoverEffect: 'zoom',
          links: merchants,
          linkCallback: openCard,
          justifyContent: 'flex-start',
        }} />
      </Merchants>
      {cardId ? (
        <MerchantCard {...{
          id: `map-popup-${id}`,
          mapId: id,
          cardId,
          closePopup,
          mapSection,
        }} />
      ) : null}
    </Background>
  )
}

export default MapSection