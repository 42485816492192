import { configureStore, combineReducers } from '@reduxjs/toolkit';
import * as ReselectTools from 'reselect-tools';

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory, createHashHistory } from 'history';
import index, { indexSelectors } from './pages/index.slice';

const historyContext = process.env.REACT_APP_HASH_HISTORY === 'true' ? createHashHistory() : createBrowserHistory();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: historyContext });

export const selectors = {
  ...indexSelectors,
};

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    index,
  }),
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }), routerMiddleware],
});

export const history = createReduxHistory(store);

ReselectTools.getStateWith(() => store.getState())  // allows you to get selector inputs and outputs
ReselectTools.registerSelectors(selectors);
if (window.location.hostname === 'localhost') {
  window.ReselectTools = ReselectTools;
  window.selectors = selectors;
}