import { HistoryRouter } from 'redux-first-history/rr6'
import { Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import './styles/globals.css'
import './fonts/gotham/stylesheet.css'
import './fonts/gotham-ssm/stylesheet.css'
import './fonts/lato/stylesheet.css'
import Index from './pages/index'
import Destination from './pages/destinations/[destination]'
import TermsOr404 from './pages/terms-or-404'
import { store, history } from './store'
import Clickstream from './components/clickstream'

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <HistoryRouter history={history}>
          <Clickstream />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/destinations/:destination" element={<Destination />} />
            <Route path="*" element={<TermsOr404 />} />
          </Routes>
        </HistoryRouter>
      </HelmetProvider>
    </Provider>
  )
}

export default App
